export const CHAIN_ID = {
  ETH_MAINNET: 1,
  RINKEBY_TESTNET: 4,
  GOERLI_TESTNET: 5,
  BINANCE_TESTNET: 97,
  BINANCE_MAINNET: 56,
  POLYGON_TESTNET: 80001,
  POLYGON_MAINNET: 137,
  ASTAR_SHIBUYA_TESTNET: 81,
  ASTAR_MAINNET: 592,
  AVAX_MAINNET: 43114,
  AVAX_TESTNET: 43113,
  // TCGVERSE_TESTNET: 12005,
  // HOMEVERSE_TESTNET: 40875,
  HOMEVERSE_MAINNET: 19011,
  MCHVERSE_MAINNET: 29548,
  TCGVERSE_MAINNET: 2400,
};

export const EIP_1559_CHAIN_ID = {
  ETH_MAINNET: 1,
  GOERLI_TESTNET: 5,
  BINANCE_TESTNET: 97,
  BINANCE_MAINNET: 56,
  POLYGON_TESTNET: 80001,
  POLYGON_MAINNET: 137,
  ASTAR_SHIBUYA_TESTNET: 81,
  ASTAR_MAINNET: 592,
  AVAX_MAINNET: 43114,
  AVAX_TESTNET: 43113,
};

export const TOKEN_ADDRESSES = {
  [CHAIN_ID.MCHVERSE_MAINNET]: {
    MCH: '0xC2a0e7AB4C3C3c602610337F54375e2e702E89DA',
  },
  [CHAIN_ID.TCGVERSE_MAINNET]: {
    TCG: '0x3B0a7182854525E9240731D4586f0FE10c77A3c2',
  },
  [CHAIN_ID.HOMEVERSE_MAINNET]: {
    HOMEDSE: '0xbd53FEE7b8d2513A788dC2F5a34cc8F630E4C8FF',
  },
  [CHAIN_ID.ETH_MAINNET]: {
    CICC: '0x6Aa9bCf20ae2FcB9A29D7D99C624d9DB43D93969',
  },
};

export const TOKEN_SYMBOLS = {
  [CHAIN_ID.MCHVERSE_MAINNET]: {
    [TOKEN_ADDRESSES[CHAIN_ID.MCHVERSE_MAINNET]['MCH']]: 'MCH',
  },
  [CHAIN_ID.TCGVERSE_MAINNET]: {
    [TOKEN_ADDRESSES[CHAIN_ID.TCGVERSE_MAINNET]['TCG']]: 'TCG',
  },
  [CHAIN_ID.HOMEVERSE_MAINNET]: {
    [TOKEN_ADDRESSES[CHAIN_ID.HOMEVERSE_MAINNET]['HOMEDSE']]: 'HOMEDSE',
  },
  [CHAIN_ID.ETH_MAINNET]: {
    [TOKEN_ADDRESSES[CHAIN_ID.ETH_MAINNET]['CICC']]: 'CICC',
  },
};

export const ZERO_FEE_CHAINS = process.env.VUE_APP_ZERO_FEE_CHAIN_IDS
  ? process.env.VUE_APP_ZERO_FEE_CHAIN_IDS.split(',').map((item) => item.replace(' ', ''))
  : [];

export const CHAIN_INFO = {
  [CHAIN_ID.RINKEBY_TESTNET]: {
    explorer: 'https://rinkeby.etherscan.io',
    name: 'Rinkeby Testnet',
    pimentName: 'rinkeby',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
    nft721Symbol: 'ERC721',
    rpcUrl: 'https://rinkeby.infura.io/v3/a75f7f0b26654f6f89101653dfb782f9',
  },
  [CHAIN_ID.GOERLI_TESTNET]: {
    explorer: 'https://goerli.etherscan.io',
    name: 'Goerli Testnet',
    pimentName: 'goerli',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
    nft721Symbol: 'ERC721',
    rpcUrl: 'https://goerli.infura.io/v3/8766da5caa9b4f90aab4b33031390558',
  },
  [CHAIN_ID.ETH_MAINNET]: {
    explorer: 'https://etherscan.io',
    name: 'Ethereum Mainnet',
    pimentName: 'ethereum',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
    acceptedTokens: [
      {
        name: 'CaicaCoin',
        symbol: 'CICC',
        decimals: 8,
        tokenAddress: TOKEN_ADDRESSES[CHAIN_ID.ETH_MAINNET]['CICC'],
      },
    ],
    nft721Symbol: 'ERC721',

    rpcUrl: 'https://mainnet.infura.io/v3/c49d59b174b6428e833462caf3c57df7',
  },
  [CHAIN_ID.BINANCE_TESTNET]: {
    explorer: 'https://testnet.bscscan.com',
    name: 'BSC Network Testnet',
    pimentName: 'binance testnet',
    nativeCurrency: {
      name: 'Binance Coin',
      symbol: 'BNB',
      decimals: 18,
    },
    nft721Symbol: 'BEP721',

    rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545',
  },
  [CHAIN_ID.BINANCE_MAINNET]: {
    explorer: 'https://bscscan.com',
    name: 'BSC Network',
    pimentName: 'binance',
    nativeCurrency: {
      name: 'Binance Coin',
      symbol: 'BNB',
      decimals: 18,
    },
    nft721Symbol: 'BEP721',

    rpcUrl: 'https://bsc-dataseed1.binance.org/',
  },
  [CHAIN_ID.POLYGON_TESTNET]: {
    explorer: 'https://mumbai.polygonscan.com',
    name: 'Polygon Mumbai Testnet',
    pimentName: 'mumbai',
    nativeCurrency: {
      name: 'Pol',
      symbol: 'POL',
      decimals: 18,
    },
    nft721Symbol: 'ERC721',

    rpcUrl: 'https://rpc-mumbai.maticvigil.com',
  },
  [CHAIN_ID.POLYGON_MAINNET]: {
    explorer: 'https://polygonscan.com',
    name: 'Polygon',
    pimentName: 'polygon',
    nativeCurrency: {
      name: 'Pol',
      symbol: 'POL',
      decimals: 18,
    },
    nft721Symbol: 'ERC721',

    rpcUrl: 'https://polygon-rpc.com/',
  },
  [CHAIN_ID.ASTAR_SHIBUYA_TESTNET]: {
    explorer: 'https://blockscout.com/shibuya',
    name: 'Astar Shibuya Testnet',
    pimentName: 'shibuya',
    nativeCurrency: {
      name: 'Shibuya',
      symbol: 'SBY',
      decimals: 18,
    },
    nft721Symbol: 'ERC721',

    rpcUrl: 'https://evm.shibuya.astar.network',
  },
  [CHAIN_ID.ASTAR_MAINNET]: {
    explorer: 'https://blockscout.com/astar',
    name: 'Astar Mainnet',
    pimentName: 'astar',
    nativeCurrency: {
      name: 'Astar',
      symbol: 'ASTR',
      decimals: 18,
    },
    nft721Symbol: 'ERC721',

    rpcUrl: 'https://evm.astar.network',
  },
  [CHAIN_ID.AVAX_MAINNET]: {
    explorer: 'https://snowtrace.io',
    name: 'Avalance C-Chain',
    pimentName: 'avalance',
    nativeCurrency: {
      name: 'Avalance',
      symbol: 'AVAX',
      decimals: 18,
    },
    nft721Symbol: 'ERC721',

    rpcUrl: 'https://api.avax.network/ext/bc/C/rpc',
  },
  [CHAIN_ID.AVAX_TESTNET]: {
    explorer: 'https://testnet.snowtrace.io',
    name: 'Avalanche FUJI C-Chain',
    pimentName: 'fuji',
    nativeCurrency: {
      name: 'Avalance',
      symbol: 'AVAX',
      decimals: 18,
    },
    nft721Symbol: 'ERC721',

    rpcUrl: 'https://api.avax-test.network/ext/bc/C/rpc',
  },
  [CHAIN_ID.TCGVERSE_MAINNET]: {
    explorer: 'https://explorer.tcgverse.xyz',
    name: 'TCG Verse Mainnet',
    pimentName: 'tcg',
    nativeCurrency: {
      name: 'Oasys',
      symbol: 'OAS',
      decimals: 18,
    },
    acceptedTokens: [
      {
        name: 'TCG Token',
        symbol: 'TCG',
        decimals: 18,
        tokenAddress: TOKEN_ADDRESSES[CHAIN_ID.TCGVERSE_MAINNET]['TCG'],
      },
    ],
    nft721Symbol: 'ERC721',

    rpcUrl: 'https://rpc.tcgverse.xyz',
  },
  // [CHAIN_ID.TCGVERSE_TESTNET]: {
  //   explorer: 'https://testnet.explorer.tcgverse.xyz',
  //   name: 'TCG Verse Testnet',
  //   nativeCurrency: {
  //     name: "Oasys",
  //     symbol: "OAS",
  //     decimals: 18,
  //   },
  //   acceptedTokens: [
  //     {
  //       name: 'TCG Token',
  //       symbol: 'TCG',
  //       decimals: 18,
  //     }
  //   ],
  //   nft721Symbol: 'ERC721',

  //   rpcUrl: 'https://testnet.rpc.tcgverse.xyz',
  // },
  // [CHAIN_ID.HOMEVERSE_TESTNET]: {
  //   explorer: 'https://explorer.testnet.oasys.homeverse.games',
  //   name: 'Home Verse Testnet',
  //   nativeCurrency: {
  //     name: "Oasys",
  //     symbol: "OAS",
  //     decimals: 18,
  //   },
  //   acceptedTokens: [
  //     {
  //       name: 'HOME Token',
  //       symbol: 'HOME',
  //       decimals: 18,
  //     }
  //   ],
  //   nft721Symbol: 'ERC721',

  //   rpcUrl: 'https://rpc.testnet.oasys.homeverse.games',
  // },
  [CHAIN_ID.HOMEVERSE_MAINNET]: {
    explorer: 'https://explorer.mainnet.oasys.homeverse.games',
    name: 'Home Verse Mainnet',
    pimentName: 'home',
    nativeCurrency: {
      name: 'Oasys',
      symbol: 'OAS',
      decimals: 18,
    },
    acceptedTokens: [
      // {
      //   name: 'HOME Token',
      //   symbol: 'HOMEDSE',
      //   decimals: 18,
      //   tokenAddress: TOKEN_ADDRESSES[CHAIN_ID.HOMEVERSE_MAINNET]['HOMEDSE'],
      // },
    ],
    nft721Symbol: 'ERC721',

    rpcUrl: 'https://rpc.mainnet.oasys.homeverse.games',
  },
  [CHAIN_ID.MCHVERSE_MAINNET]: {
    explorer: 'https://explorer.oasys.mycryptoheroes.net',
    name: 'MCH Verse Mainnet',
    pimentName: 'mch',
    nativeCurrency: {
      name: 'Oasys',
      symbol: 'OAS',
      decimals: 18,
    },
    acceptedTokens: [
      {
        name: 'MCH Token',
        symbol: 'MCH',
        decimals: 18,
        tokenAddress: TOKEN_ADDRESSES[CHAIN_ID.MCHVERSE_MAINNET]['MCH'],
      },
    ],
    nft721Symbol: 'ERC721',

    rpcUrl: 'https://rpc.oasys.mycryptoheroes.net',
  },
};

export const SUPPORTED_CHAIN_IDS = process.env.VUE_APP_SUPPORTED_CHAIN_IDS.split(',').map((el) => {
  const newEl = el.replace(' ', '');
  return Number(newEl);
});
